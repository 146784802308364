import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import Athlone from "styles/fonts/Athlone.otf";

const primary = "#D0DEF8";
const secondary = "#1D1D1D";
const info = "#D0DEF8";
const success = "#27AE60";
const warning = "#E2B93B";
const error = "#EB5757";
const black1 = "#1D1D1D";

const breakpoints = createBreakpoints({});

const athloneFont = {
  fontFamily: "Athlone, sans-serif",
  src: `
   local('Athlone'),
   url(${Athlone}) format('otf')
 `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
};

export const theme = createTheme({
  breakpoints: {
    values: {
      md: 1200
    }
  },
  palette: {
    common: {
      black: black1
    },
    primary: {
      main: "#fff"
    },
    secondary: {
      main: secondary
    },
    error: {
      main: error
    },
    success: {
      main: success
    },
    warning: {
      main: warning
    },
    info: {
      main: info
    }
  },
  typography: {
    fontFamily: "Poppins, sans-serif, Athlone",
    h1: {
      fontFamily: "Athlone",
      fontWeight: "800",
      letterSpacing: "1.2px",
      [breakpoints.down("md")]: {
        fontSize: "4.5rem",
        lineHeight: "60px"
      },
      [breakpoints.up("md")]: {
        fontSize: "6rem"
        // lineHeight: "145px"
      },
      [breakpoints.up("xl")]: {
        fontSize: "7rem"
      }
    },
    h2: {
      [breakpoints.down("md")]: {
        fontSize: "2.2rem"
      },
      [breakpoints.up("md")]: {
        fontSize: "4rem"
      },
      [breakpoints.up("xl")]: {
        fontSize: "6rem"
      }
    },
    h4: {
      fontWeight: "bold",
      fontFamily: "Athlone",
      fontSize: "4rem"
    },
    h6: {
      lineHeight: "1.7rem"
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          boxSizing: "border-box"
        },
        body: {
          fontFamily: "Inter, sans-serif",
          margin: 0,
          backgroundColor: "#fff",
          maxWidth: "100%",
          overflowX: "hidden",
          userSelect: "none"
        },
        html: {
          scrollBehavior: "smooth",
          overflowX: "hidden"
        },
        textarea: {
          padding: "6px 12px !important"
        },
        "::-webkit-scrollbar": {
          width: "0" /* Remove scrollbar space */,
          background:
            "transparent" /* Optional: just make scrollbar invisible */
        },
        "input::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: "0"
        },
        "input::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: "0"
        },
        "input[type='number']": {
          "-moz-appearance": "textfield"
        },
        ".MuiButton-containedPrimary-105": {
          backgroundColor: "black !important"
        },
        "@font-face": [athloneFont],
        ".wallet-adapter-button-start-icon": {
          display: "none"
        }
      }
    },
    MuiContainer: {
      root: {
        paddingLeft: "0",
        paddingRight: "0"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Athlone",
        fontSize: "2rem",
        height: "40px",
        borderRadius: "4px",
        textTransform: "capitalize",
        fontWeight: "800",
        color: "white !important",
        letterSpacing: "1.1px !important",
        backgroundColor: "#1d1d1d !important"
      },
      containedPrimary: {
        // color: black1,
        backgroundColor: primary,
        "&:hover": {
          backgroundColor: `${primary}e0`
        }
        // border: `2px solid ${primary}`
      },
      containedSecondary: {
        // color: black1,
        // backgroundColor: primary,
        "&:hover": {
          backgroundColor: `${secondary}e0`
        }
      },
      outlinedPrimary: {
        color: black1,
        backgroundColor: "#ffffff",
        border: `1px solid ${primary}`,
        transition: "border 0.2s ease-in",
        "&:hover": {
          border: `1px solid ${secondary}`,
          backgroundColor: "#ffffff"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&:focused": {
          color: "unset"
        }
      }
    },
    MuiLink: {
      root: {
        cursor: "pointer"
      }
    },
    MuiTab: {
      root: {
        textTransform: "capitalize",
        width: "150px"
      }
    },
    MuiSelect: {
      select: {
        textTransform: "capitalize",
        color: "#1d1d1d"
      },
      outlined: {
        borderRadius: ".5rem",
        padding: ".7rem",
        border: `1px solid ${primary}`,
        transition: "border 0.2s ease-in",
        "&:hover": {
          border: `1px solid ${secondary}`
        }
      }
    },
    MuiInputBase: {
      root: {
        // height: "40px",
        fontSize: "1.1rem",
        fontWeight: "600",
        border: ".5px solid #BDBDBD",
        borderRadius: "4px",
        padding: "6px 16px"
        // disabled: {
        //   color: "red"
        // }
      },
      input: {
        color: "white"
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        display: "none"
      }
    },
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: 0
        }
      }
    },
    MuiInputLabel: {
      formControl: {
        padding: "0 2%",
        left: "unset",
        transform: "translate(0, 16px) scale(1)"
      }
    },
    MuiAccordion: {
      root: {
        // background: "#DBE1E5",
        "&:before": {
          display: "none"
        }
      }
    },
    MuiAccordionSummary: {
      content: {
        display: "block"
      }
    }
  }
});
