const initialState = {
  contractAddress: "terra1lfr4aja5a2xpxvnrl4gyjpru0wwglu7k87jmeq", //terra heroes address
  contractNFTs: {},
};

const myNFTsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONTRACT_ADDRESS": {
      return { ...state, contractAddress: action.payload };
    }
    case "SET_CONTRACT_NFTS": {
      return { ...state, contractNFTs: action.payload };
    }
    default:
      return state;
  }
};

export default myNFTsReducer;
