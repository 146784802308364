import { Suspense, lazy } from "react";
import LoadingComponent from "./LoadingComponent";

const Navbar = lazy(() => import("sections/Navbar"));
const Footer = lazy(() => import("sections/Footer"));

const styles = {
  root: {
    paddingTop: "80px", //shifted down because of navbar
    minHeight: "90vh",
  },
};

export default function page({ children }) {
  return (
    <Suspense fallback={<LoadingComponent />}>
      <Navbar />
      <div style={styles.root}>{children}</div>
      <Footer />
    </Suspense>
  );
}
