import { useMemo, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

// components
import LoadingPage from "layouts/LoadingComponent.js";

// styles
import { ThemeProvider } from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";
import { theme } from "styles/themes/mainTheme";

// config
import CustomRoutes from "sections/Routes";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import Setup from "config/SolanaConfig";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
} from "@solana/wallet-adapter-wallets";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactGA from "react-ga";

ReactGA.initialize("UA-209804062-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet()],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <ConnectionProvider endpoint={Setup.endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <BrowserRouter>
            <CustomRoutes />
          </BrowserRouter>
        </WalletProvider>
      </ConnectionProvider>

      <ToastContainer autoClose={5000} pauseOnHover />
    </ThemeProvider>
  );
};

export default App;
