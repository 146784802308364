const initialState = {
  selectedNFTs: [],
  showVillainSwap: false,
  requestInfo: null,
};

const villainsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_NFTS": {
      return { ...state, selectedNFTs: action.payload };
    }
    case "SET_SHOW_VILLAIN_SWAP": {
      return { ...state, showVillainSwap: action.payload };
    }
    case "SET_REQUEST_INFO": {
      return { ...state, requestInfo: action.payload };
    }
    default:
      return state;
  }
};

export default villainsReducer;
