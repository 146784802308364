import { clusterApiUrl } from "@solana/web3.js";

let solanaEndpoint = clusterApiUrl("mainnet-beta");
if (window.location.hostname === "localhost" || "heronft") {
  solanaEndpoint =
    "https://solana--mainnet.datahub.figment.io/apikey/b40835fb81ce4d01b4964ddfa00b5497";
}

export const ENDPOINTS = [
  {
    name: "mainnet-beta",
    endpoint: "https://solana-api.projectserum.com",
    // endpoint: solanaEndpoint,
  },
  {
    name: "devnet",
    endpoint: clusterApiUrl("devnet"),
  },
  {
    name: "localnet",
    endpoint: "http://localhost:8899",
  },
];

const Setup = ENDPOINTS[0];

export default Setup;
