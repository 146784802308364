const initialState = {
  searchAttribute: null,
  startAt: 0,
  offset: 0,
  searchObj: {},
  fetchedNFTs: [],
  displayedNFTs: [],
  cachedImages: {},
  subcollection: "Heroes",
  hasMore: true,
};

const myNFTsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QUERY_PARAM": {
      return { ...state, searchAttribute: action.payload };
    }
    case "SET_SEARCH_OBJ": {
      return { ...state, searchObj: action.payload };
    }
    case "SET_FETCHED_NFTs": {
      return { ...state, fetchedNFTs: action.payload };
    }
    case "SET_DISPLAYED_NFTs": {
      return { ...state, displayedNFTs: action.payload };
    }
    case "ADD_CACHED_IMAGE": {
      const { name, img } = action.payload;
      const newCachedImages = { ...state.cachedImages };
      newCachedImages[name] = img;
      return { ...state, cachedImages: newCachedImages };
    }
    case "SET_SUB_COLLECTION": {
      return { ...state, subcollection: action.payload };
    }
    case "SET_START_AT": {
      return { ...state, startAt: action.payload };
    }
    case "SET_OFFSET": {
      return { ...state, offset: action.payload };
    }
    case "SET_HAS_MORE": {
      return { ...state, hasMore: action.payload };
    }
    default:
      return state;
  }
};

export default myNFTsReducer;
