const defaultState = { heroes: null, villains: null, other: null };

const initialState = {
  solanaNFTs: { ...defaultState },
  terraNFTs: { heroes: null, villains: null, other: null },
  NFTMap: {},
  selectedNFTs: [],
  subcollection: "heroes",
};

const myNFTsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SOLANA_NFTS": {
      return { ...state, solanaNFTs: action.payload };
    }
    case "SET_TERRA_NFTS": {
      return { ...state, terraNFTs: action.payload };
    }
    case "ADD_NFT_TO_MAP": {
      const { tokenId } = action.payload;
      const newNFTMap = { ...state.NFTMap };
      newNFTMap[tokenId] = action.payload;
      return { ...state, NFTMap: newNFTMap };
    }
    case "SET_MY_NFT_SUB_COLLECTION": {
      return { ...state, subcollection: action.payload };
    }
    default:
      return state;
  }
};

export default myNFTsReducer;
