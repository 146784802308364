const initialState = {
  enabled: false,
};

const fireworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_FIREWORKS": {
      return { ...state, enabled: true};
    }
    case "STOP_FIREWORKS": {
      return { ...state, enabled: false};
    }
    default:
      return state;
  }
};

export default fireworkReducer;
