import { combineReducers } from "redux";
import mintReducer from "./mintReducer";
import myNFTsReducer from "./myNFTsReducer";
import fireworkReducer from "./fireworkReducer";
import villainSwapReducer from "./villainSwapReducer";
import collectionsReducer from "./collectionsReducer";
import bridgeReducer from "./bridgeReducer";

export default combineReducers({
  mintReducer,
  myNFTsReducer,
  fireworkReducer,
  villainSwapReducer,
  collectionsReducer,
  bridgeReducer,
});
