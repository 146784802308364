import { lazy } from "react";
import { Routes, Route } from "react-router-dom";

/*
import Page from "layouts/page";
import Home from "pages/Home";
import Collections from "pages/Collections";
import FullCollection from "pages/FullCollection";
import Comics from "pages/Comics";
*/
import Page from "layouts/page";
const Home = lazy(() => import("pages/Home"));
const Collections = lazy(() => import("pages/Collections"));
const FullCollection = lazy(() => import("pages/FullCollection"));
const Comics = lazy(() => import("pages/Comics"));

export default function CustomRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Page children={<Home />} />} />
      <Route path="my-nfts" element={<Page children={<Collections />} />} />
      <Route path="comics" element={<Page children={<Comics />} />} />
      <Route
        path="fullcollection"
        element={<Page children={<FullCollection />} />}
      />
    </Routes>
  );
}
