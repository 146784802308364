const initialState = {
  coin: undefined,
  image: null,
  mintQty: 1,
  numMinted: 0,
  maxMints: undefined, //should be updated immediately
  status: "pending", //minting, success, soldout
  address: null,
  usdBal: 0,
  isWhitelisted: false,
  walletNFTs: [],
};

const mintReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COIN": {
      return { ...state, coin: action.payload };
    }
    case "SET_IMAGE": {
      return { ...state, image: action.payload };
    }
    case "SET_STATUS": {
      return { ...state, status: action.payload };
    }
    case "SET_MINT_QTY": {
      return { ...state, mintQty: action.payload };
    }
    case "SET_NUM_MINTED": {
      return { ...state, numMinted: action.payload };
    }
    case "SET_MAX_MINTS": {
      return { ...state, maxMints: action.payload };
    }
    case "SET_IS_WHITELISTED": {
      return { ...state, isWhitelisted: action.payload };
    }
    case "SET_ADDRESS": {
      return { ...state, address: action.payload };
    }
    case "SET_USD_BAL": {
      return { ...state, usdBal: action.payload };
    }
    case "SET_WALLET_NFTS": {
      return { ...state, walletNFTs: action.payload };
    }
    default:
      return state;
  }
};

export default mintReducer;
