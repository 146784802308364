import { LinearProgress } from "@mui/material";
import logo from "images/logo.webp";
import Hero from "images/heroes/astro_girl.webp";
const styles = {
  root: {},
  body: {
    textAlign: "center",
    padding: "32px",
  },
};

const LoadingComponent = () => {
  return (
    <div style={styles.root}>
      <img
        src={logo}
        alt="hero-logo"
        width="130px"
        height="35px"
        style={{ margin: "10px 0px 0px 10px" }}
      />
      <hr />
      <div style={styles.body}>
        <img src={Hero} alt="hero-temp" width="200px" />
        <h1>Loading...</h1>
        <LinearProgress
          size={100}
          sx={{
            maxWidth: "300px",
            margin: "0 auto",
          }}
        />
      </div>
    </div>
  );
};

export default LoadingComponent;
